<template>
    <div>
        <div class="app-header">
          <div class="app-logo">
            <img src="@/assets/logo.png">
          </div>
          <div class="app-title">
            <h2>{{ this.pageName }}</h2>
          </div>
        </div>
        <hr />
    </div>
</template>

<script>
export default {
  props: {
    pageName: {
      type: String,
      required: true
    }
  }
}
</script>
<style>
.app-header {
  display: flex;
  justify-content: space-between;
}

.app-logo {
  width: 40%;
  text-align: right;
  margin-right: 10px;
}

.app-title {
  font-size: 1.5em;
  font-weight: bold;
  font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  width: 60%;
  text-align: left;
  margin-top: 5px;
  margin-left: 10px;
}
</style>