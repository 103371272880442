<template>
  <div class="transcript">
    <div class="transcript-header">Official Transcript</div>
    <div class="info-container">
      <div class="student-print">
        <div style="font-weight: bold;">Student</div>
        <div>{{ student.name }}</div>
        <div>{{ student.address }}, {{ student.city }}, {{ student.state }} {{ student.zip }}</div>
        <div>{{ student.phone }}</div>
        <div>{{ student.email }}</div>
      </div>
      <div class="school-print">
        <div style="font-weight: bold;">School</div>
        <div>{{ school.name }}</div>
        <div>{{ school.address }}, {{ school.city }}, {{ school.state }} {{ school.zip }}</div>
        <div>{{ school.phone }}</div>
        <div>{{ school.email }}</div>
      </div>
    </div>
    <div class="school-year-container">
      <SchoolYearPrint v-for="(schoolYear, index) in schoolYearsData" :key="index" :schoolYear="schoolYear.year" :classList="schoolYear.classes" />
    </div>
    <div class="transcript-footer">
      <div class="credits">Cumulative Credits: {{ cumulativeCredits }}</div>
      <div class="gpa">Cumulative GPA: {{ cumulativeGpa }}</div>
    </div>
</div>
</template>

<script>
import SchoolYearPrint from '@/components/SchoolYearPrint.vue'

export default {
  data () {
    return {
      school: this.schoolData,
      student: this.studentData
    }
  },
  props: {
    schoolData: {
      type: Object,
      required: true
    },
    studentData: {
      type: Object,
      required: true
    },
    schoolYearsData: {
      type: Array,
      required: true
    }   
  },
  computed: {
    cumulativeCredits () {
      let totalCredits = 0
      this.schoolYearsData.forEach(schoolYear => {
        schoolYear.classes.forEach(classItem => {
          totalCredits += classItem.credits
        })
      })
      return totalCredits
    },
    cumulativeGpa () {
      let totalGradePoints = 0
      this.schoolYearsData.forEach(schoolYear => {
        schoolYear.classes.forEach(classItem => {
          totalGradePoints += (classItem.credits * classItem.grade)
        })
      })
      return (totalGradePoints / this.cumulativeCredits).toFixed(2)
    }
  },
  components: {
    SchoolYearPrint
  }
}
</script>
<style scoped>
.transcript {
  border: 1px solid black;
  margin: 20px; 
}

.transcript-header {
  color: white;
  font-weight: bold;
  font-size: 1.2em;  
  background-color: black;
  text-align: center;
}

.info-container {
  display: flex;
  justify-content: space-between;
}

.school-year-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.school-print {
  padding: 10px;
  margin: 10px;
  text-align: left;
}

.student-print {
  padding: 10px;
  margin: 10px;
  text-align: left;
}

.transcript-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
}

.credits {
  text-align: right;
  padding-right: 20px;
}

.gpa {
  text-align: left;
  padding-left: 20px;
}
</style>
