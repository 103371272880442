import Vue from 'vue'
import Vuex from 'vuex'
import { setStore, getStore } from '@/config/utils'

Vue.use(Vuex)

const user = getStore('user')
const heartToken = getStore('heartToken')

export default new Vuex.Store({
  state: {
    loginUser: user,
    heartUser: heartToken
  },
  mutations: {
    setLoginUser (state, user) {
      state.loginUser = user
      setStore('user', user)
    },
    setHeartToken (state, heartToken) {
      state.heartUser = heartToken
      setStore('heartToken', heartToken)
    }
  },
  actions: {

  },
  getters: {
    getLoginUserInfo (state) {
      return state.loginUser
    }
  }
})
