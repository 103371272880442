export const gridOptions = {
  defaultColDef: {
    flex: 1,
    resizable: true,
    sortable: true,
    filter: true
  },
  columnDefs: [
    {
      field: 'tenantId',
      hide: true
    },
    {
      field: 'id',
      hide: true
    },
    {
      headerName: 'User Name',
      field: 'userName'
    },
    {
      headerName: 'Display Name',
      field: 'displayName',
      editable: true
    }
  ]
}
