<template>
  <div>
    <div class="info-container">
      <div class="student">
        <div><label for="studentName">Student Name: <input id="studentName" type="text" v-model="student.name" /></label></div>
        <div><label for="studentAddress">Address: <input id="studentAddress" type="text" v-model="student.address" /></label></div>
        <div><label for="studentCity">City: <input id="studentCity" type="text" v-model="student.city" /></label></div>
        <div><label for="studentState">State: <input id="studentState" type="text" v-model="student.state" /></label></div>
        <div><label for="studentZip">Zip: <input id="studentZip" type="text" v-model="student.zip" /></label></div>
        <div><label for="studentPhone">Phone: <input id="studentPhone" type="text" v-model="student.phone" /></label></div>
        <div><label for="studentEmail">Email: <input id="studentEmail" type="text" v-model="student.email" /></label></div>
      </div>
      <div class="school">
        <div><label for="schoolName">School Name: <input id="schoolName" type="text" v-model="school.name" /></label></div>
        <div><label for="schoolAddress">Address: <input id="schoolAddress" type="text" v-model="school.address" /></label></div>
        <div><label for="schoolCity">City: <input id="schoolCity" type="text" v-model="school.city" /></label></div>
        <div><label for="schoolState">State: <input id="schoolState" type="text" v-model="school.state" /></label></div>
        <div><label for="schoolZip">Zip: <input id="schoolZip" type="text" v-model="school.zip" /></label></div>
        <div><label for="schoolPhone">Phone: <input id="schoolPhone" type="text" v-model="school.phone" /></label></div>
        <div><label for="schoolEmail">Email: <input id="schoolEmail" type="text" v-model="school.email" /></label></div>
      </div>
    </div>
    <div>
      <SchoolYearInput v-for="(schoolYear, index) in schoolYears" :key="index" :schoolYearData="schoolYear" />
    </div>
    <div><button @click="schoolYearAdded">Add School Year</button></div>
  </div>
</template>

<script>

import SchoolYearInput from '@/components/SchoolYearInput.vue'

export default {
  data () {
    return {
      school: this.schoolData,
      student: this.studentData,
      schoolYears: this.schoolYearsData
    }
  },
  components: {
    SchoolYearInput
  },
  props: {
    schoolData: {
      type: Object,
      required: true
    },
    studentData: {
      type: Object,
      required: true
    },
    schoolYearsData: {
      type: Array,
      required: true
    }
  },
  methods: {
    schoolYearAdded () {
      // console.log("schoolYearAdded in TranscriptInput.vue")

      let schoolYear = {
        yearId: this.schoolYears.length + 1,
        year: '',
        classes: []
      }
      
      this.schoolYears.push(schoolYear)
      // console.log("All school years in transcriptInput.vue " + JSON.stringify(this.schoolYears))
    }
  }
}
</script>
<style>
.info-container {
    display: flex;
    justify-content: space-between;
}

.school {
    padding: 10px;
    margin: 10px;
    text-align: right;
}

.student {
    padding: 10px;
    margin: 10px;
    text-align: right;
}

label {
    font-weight: bold;
}

input {
  font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    height: 20px;
    width: 200px;
    margin-bottom: 2px;
}
</style>
