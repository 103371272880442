<template>
    <div>
        <Header :pageName='pageName' />
        <div>
            <button @click="generatePDF">Print</button>
        </div>
        <hr />
        <div id="pdf-content">
            <VueHtml2pdf
                :show-layout="false"
                :float-layout="true"
                :paginate-elements-by-height="1400"
                :enable-download="true"
                :preview-modal="true"
                :pdf-quality="2"
                filename="transcript.pdf"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"
                margin="10px"
                ref="html2Pdf">
                <section slot="pdf-content">
                  <TranscriptPrint :schoolData="school" :studentData="student" :schoolYearsData="schoolYears" />
                </section>
            </VueHtml2pdf>
        </div>
        <div>
            <TranscriptInput 
              :schoolData="school"
              :studentData="student"
              :schoolYearsData="schoolYears" />
        </div>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import TranscriptPrint from '@/components/TranscriptPrint.vue'
import TranscriptInput from '@/components/TranscriptInput.vue'
import Header from '@/components/widgets/HeaderWidget.vue'
export default {
  components: {
    Header,
    VueHtml2pdf,
    TranscriptPrint,
    TranscriptInput
  },
  data () {
    return {
      pageName: 'Transcript Editor',
      school: {
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: ''
      },

      student: {
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: ''
      },

      schoolYears: []
    }
  },
  methods: {
    generatePDF () {
      this.$refs.html2Pdf.generatePdf()
    }
  }
}
</script>
<style>
.app-header {
  display: flex;
  justify-content: space-between;
}

.app-logo {
  width: 40%;
  text-align: right;
  margin-right: 10px;
}

.app-title {
  font-size: 1.5em;
  font-weight: bold;
  font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  width: 60%;
  text-align: left;
  margin-top: 5px;
  margin-left: 10px;
}

button {
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  background-color: #37CA37;
  border: none;
}
</style>