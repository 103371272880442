import { getStore } from '@/config/utils'

const apiRoot = process.env.VUE_APP_APIROOT

// invokes a GET on the apiName to list the objects
export const apiList = async (apiName) => {
  console.log(`fetching ${apiName} list`)

  var heartUser = getStore('heartToken')

  try {
    const res = await fetch(`${apiRoot}/${apiName}`, {
      method: 'get',
      headers: {
        'Authorization': `Bearer ${heartUser.authToken}`,
        'Content-Type': 'application/json'
      }
    })

    if (!res.ok) {
      // a non-200 response code
      console.log(`GET ${apiName} response was not http.ok`)
      console.log(res)
      throw new Error(res.statusText)
    }

    return await res.json()
  } catch (err) {
    // In case a custom JSON error response was provided
    if (err.json) {
      return err.json.then(json => {
        // set the JSON response message
        console.log(json.message)
      })
    }
  }
}

// invokes a GET on the apiName/id to retrieve a specific object
export const apiGetById = async (apiName, id) => {
  console.log(`fetching ${apiName}/${id} item`)

  var heartUser = getStore('heartToken')
  console.log(heartUser)
  
  try {
    const res = await fetch(`${apiRoot}/${apiName}/${id}`, {
      method: 'get',
      headers: {
        'Authorization': `Bearer ${heartUser.authToken}`,
        'Content-Type': 'application/json'
      }
    })

    if (!res.ok) {
      // a non-200 response code
      console.log(`GET ${apiName}/${id} response was not http.ok`)
      console.log(res)
      throw new Error(res.statusText)
    }

    return res.json()
  } catch (err) {
    console.log(`catching ${apiName} error`)
    console.log(err)
    // In case a custom JSON error response was provided
    if (err.json) {
      return err.json.then(json => {
        // set the JSON response message
        console.log(json.message)
      })
    }
  }
}

// invokes a POST on the apiName with body as the payload to insert a new object
export const apiPost = async (apiName, body) => {
  console.log(`posting to ${apiRoot}/${apiName}`)

  var heartUser = getStore('heartToken')

  try {
    const res = await fetch(`${apiRoot}/${apiName}`, {
      method: 'post',
      headers: {
        'Authorization': `Bearer ${heartUser.authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })

    if (!res.ok) {
      // a non-200 response code
      console.log(`POST ${apiName} response was not http.ok`)
      throw new Error(`status text ${res.statusText}`)
    }

    return await res.json()
  } catch (err) {
    // In case a custom JSON error response was provided
    if (err.json) {
      return err.json.then(json => {
        // set the JSON response message
        console.log(json.message)
      })
    }
  }
}

// invoke a PUT on the apiName with body as the payload to update an existing object
export const apiPutById = async (apiName, id, body) => {
  console.log(`putting by id to ${apiName}/${id}`)

  var heartUser = getStore('heartToken')

  try {
    const res = await fetch(`${apiRoot}/${apiName}/${id}`, {
      method: 'put',
      headers: {
        'Authorization': `Bearer ${heartUser.authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })

    if (!res.ok) {
      // a non-200 response code
      console.log(`PUT ${apiName} response was not http.ok`)
      console.log(res)
      throw new Error(res.statusText)
    }

    return await res.json()
  } catch (err) {
    // In case a custom JSON error response was provided
    if (err.json) {
      return err.json.then(json => {
        // set the JSON response message
        console.log(json.message)
      })
    }
  }
}

// invoke a PUT on the apiName with body as the payload to update an existing object
export const apiPut = async (apiName, body) => {
  console.log(`putting to ${apiName}`)

  var heartUser = getStore('heartToken')

  try {
    const res = await fetch(`${apiRoot}/${apiName}`, {
      method: 'put',
      headers: {
        'Authorization': `Bearer ${heartUser.authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })

    if (!res.ok) {
      // a non-200 response code
      console.log(`PUT ${apiName} response was not http.ok`)
      console.log(res)
      throw new Error(res.statusText)
    }

    return res.json()
  } catch (err) {
    // In case a custom JSON error response was provided
    if (err.json) {
      return err.json.then(json => {
        // set the JSON response message
        console.log(json.message)
      })
    }
  }
}

// invokes a DELETE on the apiName/id to retrieve a specific object
export const apiDeleteById = async (apiName, id) => {
  console.log(`deleting ${apiName}/${id} item`)

  var heartUser = getStore('heartToken')

  try {
    const res = await fetch(`${apiRoot}/${apiName}/${id}`, {
      method: 'delete',
      headers: {
        'Authorization': `Bearer ${heartUser.authToken}`,
        'Content-Type': 'application/json'
      }
    })

    if (!res.ok) {
      // a non-200 response code
      console.log(`DELETE ${apiName}/${id} response was not http.ok`)
      console.log(res)
      throw new Error(res.statusText)
    }
  } catch (err) {
    console.log(`catching ${apiName} error`)
    console.log(err)
    // In case a custom JSON error response was provided
    if (err.json) {
      return err.json.then(json => {
        // set the JSON response message
        console.log(json.message)
      })
    }
  }
}
