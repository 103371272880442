<template>
    <div class="maintenance-container">
        <h3>User Maintenance</h3>

        <form @submit.prevent="onSubmit">
            <label for="TenantId">Tenant Id: </label>
            <input id="TenantId" v-model="tenantId" />
            <br />
            <label for="UserName">User Name: </label>
            <input id="UserName" v-model="userName" />
            <br />
            <label for="DisplayName">Display Name: </label>
            <input id="DisplayName" v-model="displayName" />
            <br />
            <input class="button" type="submit" value="Add" />
        </form>

        <ul>
            <li v-for="(user, index) in users" :key="index">
                {{ user.tenantId }} {{ user.id }} {{ user.userName }} {{ user.displayName }}
            </li>
        </ul>
    </div>
</template>
<script>
export default {
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      tenantId: '',
      id: '',
      userName: '',
      displayName: ''
    }
  },
  methods: {
    onSubmit () {
      if (this.tenantId === '' || this.userName === '' || this.displayName === '') {
        alert('All fields required')
        return
      }

      let user = {
        TenantId: this.tenantId,
        UserName: this.userName,
        DisplayName: this.displayName
      }

      this.$emit('user-added', user)

      this.tenantId = ''
      this.id = ''
      this.userName = ''
      this.displayName = ''
    }
  }
}
</script>
