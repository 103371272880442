<template>
  <div class="main-container">
    <Header :pageName='pageName' />
    <form @submit.prevent="signIn">
      <div class="box-container">
        <div class="input-container">
          <label for="email">Email</label>
          <input type="email" id="userName" name="userName" required>
        </div>
        <div class="input-container">
          <label for="password">Password</label>
          <input type="password" id="password" name="password" required>
        </div>
        <div class="input-container">
          <button type="submit" class="btn">Sign In</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import router from '@/router/router'
import Header from '@/components/widgets/HeaderWidget.vue'
export default {
  components: {
    Header
  },
  data () {
    return {
      pageName: 'Sign In'
    }
  },
  methods: {
    signIn () {
      let user = {
        UserName: this.$el.querySelector('#userName').value,
        Password: this.$el.querySelector('#password').value
      }
      
      var apiRoot = process.env.VUE_APP_APIROOT

      //todo: don't hard code this
      var apiName = 'authentication/password'

      console.log(user)
      console.log('here comes the new log')
      console.log(apiRoot)
      console.log('that should show the apiUrl')
      console.log(`posting to ${apiRoot}/${apiName}`)

      try {
        fetch(`${apiRoot}/${apiName}`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(user)
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(response.statusText)
          }
          return response.json()
        })
        .then(json => {
          console.log(`${apiName} response was ok`)
          console.log(json)
          this.bearerToken = 'Bearer ' + json.authToken
          this.$store.commit('setHeartToken', json)

          //todo: not completely sure this is the right way to do this
          this.$store.commit('setLoginUser', json.user)
          
          router.push('/dashboard')
        })
        .catch(err => {
          console.log(`POST ${apiName} response was not ok`)
          console.log(err)
        })
      }
      catch (err) {
        console.log(`POST ${apiName} failed`)
        console.log(err)
      }
    }
  }
}
</script>
