<template>
  <div>
    <div class="school-year-header">{{ schoolYear }}</div>
    <div class="class-grid">
      <div class="class-header">
        <div>Class Title</div>
        <div>Credits</div>
        <div>Grade</div>
      </div>
      <div class="class-item" v-for="(classItem, index) in classList" :key="index">
        <div>{{ classItem.classTitle }}</div>
        <div>{{ classItem.credits }}</div>
        <div>{{ gradeLabel[index] }}</div>
      </div>
    </div>
    <div class="class-footer">
      <div class="credits">Credits: {{ totalCredits }}</div>
      <div class="gpa">GPA: {{ gpa }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    schoolYear: {
      type: String,
      required: true
    },
    classList: {
      type: Array,
      required: true
    }
  },
  computed: {
    totalCredits () {
      return this.classList.reduce((acc, classItem) => {
        return acc + classItem.credits
      }, 0)
    },
    gpa () {
      return (this.classList.reduce((acc, classItem) => {
        return acc + (classItem.credits * classItem.grade)
      }, 0) / this.totalCredits).toFixed(2)
    },
    gradeLabel () {
      return this.classList.map(classItem => {
        switch (classItem.grade) {
          case 4.0: return 'A';
          // case 3.7: return 'A-';
          // case 3.3: return 'B+';
          case 3.0: return 'B';
          // case 2.7: return 'B-';
          case 2.0: return 'C';
          case 1.0: return 'D';
          case 0.0: return 'F';
          default: return '';
        }
      });
    }
  }
}
</script>
<style>
.school-year-header {
  color: white;
  font-weight: bold;
  background-color: black;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.class-grid {
  display: grid;
  grid-template-columns: 5fr 1.5fr 1fr;
  gap: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.class-header {
  display: contents;
  text-align: left;
  font-weight: bold;
}

.class-item {
  display: contents;
  text-align: left;
}

.class-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
</style>