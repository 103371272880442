<template>
  <div>
    <Header :pageName='pageName' />
    <div class='row'>
      <div class='column'>
        <Tenant :tenants='tenantList' @tenant-added='addTenant' />
      </div>
      <div class='column'>
        <User :users='userList' @user-added='addUser' />
      </div>
    </div>
  </div>
</template>
<script>
import { apiList, apiPost } from '@/config/apiUtils'
import Tenant from '@/components/widgets/TenantWidget.vue'
import User from '@/components/widgets/UserWidget.vue'
import Header from '@/components/widgets/HeaderWidget.vue'
export default {
  components: {
    Header,
    Tenant,
    User
  },
  data () {
    return {
      pageName: 'Administration',
      searchText: '',
      tenantList: [],
      userList: []
    }
  },
  methods: {
    getTenantList () {
      apiList('tenant').then(data => {
        console.log(data)
        this.tenantList = data
      })
    },
    addTenant (tenant) {
      apiPost('tenant', tenant)
    },
    getUserList () {
      apiList('user').then(data => {
        console.log(data)
        this.userList = data
      })
    },
    addUser (user) {
      apiPost('user', user)
    }
  },
  created () {
    this.getTenantList()
    this.getUserList()
  }
}
</script>
