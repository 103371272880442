<template>
    <div class="loginsuccess-container">
      <Header :pageName='pageName' />
      <p>404 Page not Found</p>
      <router-link to="/login">Go Back to Login</router-link>
    </div>
</template>

<script>
import Header from '@/components/widgets/HeaderWidget.vue'
export default {
  name: 'page_not_found',
  data () {
    return {
      pageName: '404 Page Not Found'
    }
  },
  components: {
    Header
  }
}
</script>
