import Vue from 'vue'
import Router from 'vue-router'
import DashboardPage from '@/views/DashboardPage'
import PageNotFound from '@/views/PageNotFound'
import AdministrationPage from '@/views/AdministrationPage'
import LoginPage from '@/views/LoginPage'
import TranscriptEditor from '@/views/TranscriptEditor'
Vue.use(Router)

let baseRoutes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/administration',
    name: 'Administration',
    component: AdministrationPage
  },
  {
    path: '/transcript',
    name: 'Transcript',
    component: TranscriptEditor
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound
  }
]

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  base: process.env.BASE_URL,
  routes: baseRoutes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  if (authRequired && !loggedIn) {
    console.log(`redirecting to login from router beforeEach because authRequired=${authRequired} and loggedIn=${loggedIn}`)
    return next('/login')
  }
  console.log('proceeding to next from router beforeEach')
  next()
})

export default router
