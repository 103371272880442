<template>
    <div class="maintenance-container">
        <h3>Tenant Maintenance</h3>

        <form @submit.prevent="onSubmit">
            <label for="Name">Name: </label>
            <input id="Name" v-model="name" />

            <input class="button" type="submit" value="Add" />
        </form>

        <ul>
            <li v-for="(tenant, index) in tenants" :key="index">
                {{ tenant.id }} {{ tenant.name }}
            </li>
        </ul>
    </div>
</template>
<script>
export default {
  props: {
    tenants: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      name: ''
    }
  },
  methods: {
    onSubmit () {
      if (this.name === '') {
        alert('Name is required')
        return
      }

      let tenant = {
        Name: this.name
      }

      this.$emit('tenant-added', tenant)

      this.name = ''
    }
  }
}
</script>
