<template>
  <div>
    <Header :pageName='pageName' />
    <!-- <h2>{{tenant.name}} Dashboard</h2> -->
    <div>
      <ag-grid-vue
      style="width: 100%; height: 200px"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :rowSelection="rowSelection"
      :editType="editType"
      :defaultColDef="defaultColDef"
      @grid-ready="onGridReady"
      @row-value-changed="onRowValueChanged"
      >
    </ag-grid-vue>
    </div>
    <div class="main-container">
      <div class="loginsuccess-container">
        <div>
          <p>Welcome {{ this.user.displayName }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue'
import { gridOptions } from './UserGridColumns.js'
import { getStore } from '@/config/utils'
import { apiList, apiPutById, apiPost } from '@/config/apiUtils'
import Header from '@/components/widgets/HeaderWidget.vue'
export default {
  data () {
    return {
      pageName: 'Dashboard',
      tenant: null,
      user: null,
      columnDefs: null,
      rowSelection: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      editType: null,
      defaultColDef: null
    }
  },
  components: {
    AgGridVue,
    Header
  },
  methods: {
    onRowValueChanged (event) {
      var changes = JSON.stringify(event.data)
      console.log(`rowvaluechange`)
      console.log(changes)

      if (event.data.id > 0) {
        console.log('update')
        apiPutById('user', event.data.id, event.data)
      } else {
        console.log('insert')
        var data = event.data
        data.tenantId = this.user.tenantId
        apiPost('user', data)
      }
    },
    onGridReady (params) {
      console.log('grid ready')
      this.gridApi = params.api
      this.columnApi = params.columnApi
    },
    getUserList () {
      apiList(`tenant/${this.tenant.id}/users`).then(data => {
        console.log(JSON.stringify(data))
        this.rowData = data
      })
    }
  },
  created () {
    console.log('dashboard created')
    var heartToken = getStore('heartToken')
    this.tenant = heartToken.tenant
    this.user = heartToken.user

    console.log(JSON.stringify(heartToken))
    console.log(JSON.stringify(this.tenant))
    console.log(JSON.stringify(this.user))

    this.pageName = `${this.tenant.name} Dashboard`
    this.editType = 'fullRow'
  },
  beforeMount () {
    this.rowSelection = 'multiple'
    this.defaultColDef = gridOptions.defaultColDef
    this.columnDefs = gridOptions.columnDefs

    this.getUserList()
  }
}
</script>
