<template>
  <div style="width: 100vw; margin: 2px 2px 2px 2px;">
    <div class="school-year">
        <div class="school-year-label">
          <label>School Year: <input name="schoolYear" type="text" v-model="schoolYear.year" /></label>
        </div>
        <div class="school-year-add-classes">
          <button @click="addClass">Add Class</button>
        </div>
    </div>
    <div style="margin-left: 10px; margin-right: 10px; margin-bottom: 10px; height: 300px;">
      <ag-grid-vue
        style="width: 100%; height: 100%;"
        class="ag-theme-alpine"
        :columnDefs="columnDefs"
        :rowData="schoolYear.classes"
        :rowSelection="rowSelection"
        :editType="editType"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady">
      </ag-grid-vue>
    </div>
  </div>
</template>
<script>
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue'
import { gridOptions } from './ClassesGridColumns.js'
export default {
  components: {
    AgGridVue
  },
  data () {
    return {
      schoolYear: this.schoolYearData,
      columnDefs: null,
      rowSelection: null,
      gridApi: null,
      columnApi: null,
      editType: null,
      defaultColDef: null
    }
  },
  props: {
    schoolYearData: {
      type: Object,
      required: true
    }
  },
  methods: {
    onGridReady (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
    },
    addClass () {
      console.log("adding class")

      let rd = {
        classId: this.schoolYear.classes.length + 1,
        classTitle: '',
        credits: null,
        grade: ''
      }

      this.schoolYear.classes.push(rd)
      this.gridApi.setGridOption('rowData', this.schoolYear.classes)
    }
  },
  beforeMount () {
    this.defaultColDef = gridOptions.defaultColDef
    this.columnDefs = gridOptions.columnDefs
    this.rowSelection = 'multiple'
    this.editType = 'fullRow'
  }
}
</script>
<style>
.school-year {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
}

.school-year-label {
  width: 50%;
  margin-right: 20px;
  text-align: right;
  margin-left: 10px;
}

.school-year-add-classes {
  width: 50%;
  margin-left: 20px;
  text-align: left;
}
</style>
