export const gridOptions = {
  defaultColDef: {
    flex: 1,
    resizable: true,
    sortable: true,
    filter: false
  },
  columnDefs: [
    {
      field: 'classId',
      hide: true
    },
    {
      headerName: 'Class Title',
      field: 'classTitle',
      width: 250,
      editable: true
    },
    {
      headerName: 'Credits',
      field: 'credits',
      width: 100,
      editable: true,
      cellDataType: 'number'
    },
    {
      headerName: 'Grade',
      field: 'grade',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [4, 3, 2, 1, 0]
      },
      filter: 'agSetColumnFilter',
      refData: {
        4: 'A',
        3: 'B',
        2: 'C',
        1: 'D',
        0: 'F'
      },
      width: 100,
      editable: true
    }
  ]
}
